import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_4";
import BlockPrincipal from "../components/block/Block_swiper";
import BlockSecondary from "../components/block/Block_3";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          bgimg={`${rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[0] : rpdata?.stock?.[0]}`}
        />
        <BlockPrincipal
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.length > 6 ? rpdata?.gallery?.[19] : rpdata?.stock?.[6]}
          corte={rpdata?.gallery}
          listsAbout
        />
        <ValuesOne
          image1={rpdata?.gallery?.length > 7 ? rpdata?.gallery?.[21] : rpdata?.stock?.[7]}
          image2={rpdata?.gallery?.length > 8 ? rpdata?.gallery?.[22] : rpdata?.stock?.[8]}
          image3={rpdata?.gallery?.length > 9 ? rpdata?.gallery?.[23] : rpdata?.stock?.[9]}

        />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.gallery?.length > 10 ? rpdata?.gallery?.[24] : rpdata?.stock?.[10]}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
